.about-row-title {
    background-color: white;
    background-image: url(./images/background2.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    margin-bottom: 2%;
    width: 100%;
    height: 80vh;
    text-align: center;
}

.about-row-title h1 {
    width: 100%;
    text-align: center;
    color: #1888ff;
    font-family: 'Montserrat';
    font-weight: 1000;
    font-size: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about-row {
    background-color: white;
    display: flex;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    flex-wrap: wrap;
    padding: 2%;
    text-align: left;
}

.about-row h2 {
    font-family: 'Montserrat';
    width: 100%;
    text-align: left;
    font-size: 25px;
    color: black;
}

.about-row ol {
    width: 98%;
    margin-left: 2%;
}

.about-row li {
    font-family: 'Montserrat';
    font-size: 20px;
    text-align: left;
    width: 100%;
    color: rgb(56, 55, 55);
    padding-top: 1%;
}


.about-row p{
    width: 100%;
    font-family: 'Montserrat';
    font-weight: 100;
    font-size: 20px;
    color: rgb(56, 55, 55);
    padding-top: 1%;
}

.events-page {
    width: 18%;
    margin-top: 5%;
}

.events-page a{
    border: 1px solid black;
    background-color: white;
    padding: 6% 8%;
    text-align: center;
    display:inline-block;
    text-decoration: none !important;
    margin:0 auto;
    font-size: 20px;
    color: rgb(56, 55, 55);
}

.events-page:hover a{
    background-color: rgb(56, 55, 55);
    color: white;
}

.events-img {
    width: 100%;
}

.events-img img {
    width: 30%;
    height: 200px;
    margin: 1%;
}

.events-img img:hover {
    transform: scale(1.5);
}

@media screen and (max-width: 800px) {
    .about-row-title {
        height: 50vh;
    }
    .about-row-title h1 {
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media screen and (max-width: 600px) {
    .about-row {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
    
}
.events-content {
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    width: 100%;
    height: auto;
    background-color: white;
}

.events-row-title {
    background-color: white;
    background-image: url(./images/background4.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    margin-bottom: 2%;
    width: 100%;
    height: 80vh;
    text-align: center;
}

.events-row-title h1 {
    width: 100%;
    text-align: center;
    color: #1888ff;
    font-family: 'Montserrat';
    font-weight: 1000;
    font-size: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.events-row {
    display: flex;
    flex-wrap: wrap;
    padding: 2em 1em;
    text-align: center;
}

.events-row h2 {
    width: 100%;
    text-align: center;
    font-size: 3.5em;
    color: #1888ff;
    font-family: 'Montserrat';
}

.events-row h3 {
    width: 100%;
    text-align: center;
    font-size: 2.5em;
    color: #000000;
    font-family: 'Montserrat';
}

.events-column {
    display: flex;
    max-width: 100%;
    width: 100%;
    padding: 0 1em;
}

.events-card {
    box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
    padding: 5em 1em;
    border-radius: 0.6em;
    color: #1f003b;
    transition: 0.3s;
    background-color: #ffffff;
    margin-top: 4%;
    display: flex;
    width: 100%;
}

.events-card .events-img-container {
    width: 40%;
    height: 100%;
}

.events-card img {
    margin-right: auto;
    margin-left: auto;
    width: 60%;
    height: 80%;
    
}

.events-card .text{
    width: 55%;
    margin-left: 3%;
    height: 100%;
}

.events-card .text h3{
    color: #1888ff;
    font-size: 35px;
    font-family: 'Montserrat';
}

.events-card .text h4{
    color: black;
    text-align: left;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 100;
    margin-top: 5%;
}

.events-card .text .register {
    height: 100px;
    margin-top: 5%;
}

.events-card .text .register a{
    border: 1px solid black;
    background-color: white;
    padding: 3% 6%;
    text-align: center;
    display:inline-block;
    text-decoration: none !important;
    margin:0 auto;
    font-size: 22px;
    color: black;
}

/*
.events-card:hover {
    background: linear-gradient(white);
    color: #ffffff;
}
.events-card:hover .events-img-container {
    transform: scale(1.15);
}

.events-card:hover h4{
    transform: scale(1.15);
    color: black;
}

.events-card:hover h3{
    transform: scale(1.15);
}
*/

@media screen and (max-width: 800px) {
    .events-card .events-img-container {
        width: 0%;
        height: 0%;
    }
    .events-card img {
        display: none;
    }
    .events-card .text{
        width: 100%;
        margin-left: 3%;
        height: 100%;
    }
}
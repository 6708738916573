.constitution-row-title{
    background-color: white;
    background-image: url(./images/background2.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    margin-bottom: 2%;
    width: 100%;
    height: 80vh;
    text-align: center;
}

.constitution-row-title h1 {
    width: 100%;
    text-align: center;
    color: #1888ff;
    font-family: 'Montserrat';
    font-weight: 1000;
    font-size: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.constitution-row {
    /*background-color: white;*/
    /*background-image: url(./images/background2.jpg);*/
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 2em 1em;
    text-align: center;
}

.constitution-row h1 {
    width: 100%;
    font-family: 'Dosis';
    text-align: center;
    font-size: 3.5em;
    color: #1888ff;
}

.constitution-row h2 {
    margin-top: 1%;
    width: 100%;
    text-align: left;
    font-size: 2em;
    color: #1888ff;
    font-family: 'Dosis';
}

.constitution-row h3 {
    width: 90%;
    margin-left: 8%;
    margin-top: 1%;
    margin-bottom: 1%;
    text-align: left;
    font-size: 20px;
    color:rgb(100, 100, 100);
    font-family: 'Montserrat';
    font-weight: 100;
}

.constitution-row ol {
    margin-left: 5%;
    text-align: left;
    color:rgb(100, 100, 100);
    font-family: 'Montserrat';
    font-weight: 100;
}

.constitution-row li {
    text-align: left;
    color:rgb(100, 100, 100);
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 100;
}
.resources-content {
    background-color: white;
}

.resources-row-title {
    background-color: white;
    background-image: url(./images/background2.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    margin-bottom: 2%;
    width: 100%;
    height: 80vh;
    text-align: center;
}

.resources-row-title h1 {
    width: 100%;
    text-align: center;
    color: #1888ff;
    font-family: 'Montserrat';
    font-weight: 1000;
    font-size: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.resources-row {
    background-color: white;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 2em 1em;
    text-align: center;
}

.resources-row h2 {
    margin-top: 1%;
    width: 100%;
    text-align: center;
    font-size: 3.55em;
    color: #1888ff;
    font-family: 'Montserrat';
}

.resources-row {
    display: flex;
    flex-wrap: wrap;
    padding: 2em 1em;
    text-align: center;
}

.resources-column {
    flex: 0 0 33.33%;
    max-width: 100%;
    padding: 0 1em;
}

.resources-card {
    box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
    padding: 5em 1em;
    border-radius: 0.6em;
    color: #1f003b;
    transition: 0.3s;
    background-color: #ffffff;
    margin-top: 4%;
}

.resources-img {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    height: 400px;
    background-color: #1888ff;
    margin-bottom: 5%;
}

.resources-img img{
    height: 100%;
    width: 100%;
} 

.resources-link {
    width: 95%;
    min-width: 180px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: auto;
}

.resources-link a {
    width: 100%;
    height: 40px;
    padding: 0.8%;
    border: 1px solid rgb(55, 54, 54);
    background-color: transparent;
    text-decoration: none;
    margin: auto;
    color: black;
    font-size: 20px;
    font-family: 'Montserrat';
}

.resources-link:hover {
    background-color: #1888ff;
}

@media screen and (max-width: 550px) {
    .resources-card {
        padding: 3.5em 1em;
        border-radius: 0.6em;
        color: #1f003b;
        cursor: pointer;
        transition: 0.3s;
        background-color: #ffffff;
    }
    .resources-column {
        flex: 0% 0% 0% 0%;
        width: 100%;
        margin: 0%;
        padding: 0.5em 0;
    }
}
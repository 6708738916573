
.content {
    padding: 0%;
    border: 2px solid black;
    width: 100%;
}

.backImage {
    margin: 0%;
    width: 100%;
    height: 100vh;
    background-image: url(./images/background.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    margin-bottom: 5%;
}

.backImage h1{
    color: #1888ff;
    width: 60%;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 40px;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.socials {
    width: 375px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;

}


  
.contact-content {
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    width: 100%;
    height: auto;
    background-color: white;
}

.contact-row-title {
    background-color: white;
    background-image: url(./images/background4.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    margin-bottom: 2%;
    width: 100%;
    height: 80vh;
    text-align: center;
}

.contact-row-title h1 {
    width: 100%;
    text-align: center;
    color: #1888ff;
    font-family: 'Montserrat';
    font-weight: 1000;
    font-size: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contact-row {
    display: flex;
    flex-wrap: wrap;
    padding: 2em 1em;
    text-align: center;
}

.contact-row h2 {
    width: 100%;
    text-align: center;
    font-size: 3.5em;
    color: #1888ff;
    font-family: 'Montserrat';
}

.contact-column {
    display: flex;
    width: 100%;
    padding: 0 1em;
}

.contact-card {
    box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
    padding: 5em 1em;
    border-radius: 0.6em;
    color: #1f003b;
    transition: 0.3s;
    background-color: #ffffff;
    margin-top: 4%;
    display: flex;
}
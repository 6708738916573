
.content {
    background-repeat: repeat-y;
    background-repeat: repeat-x;
    width: 100%;
    height: auto;
}

.team-row-title {
    background-color: white;
    background-image: url(./images/background3.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    margin-bottom: 2%;
    width: 100%;
    height: 80vh;
    text-align: center;
}

.team-row-title h1 {
    width: 100%;
    text-align: center;
    color: #1888ff;
    font-family: 'Montserrat';
    font-weight: 1000;
    font-size: 50px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 2em 1em;
    text-align: center;
}
.column {
    flex: 0 0 33.33%;
    max-width: 100%;
    padding: 0 1em;
}

h1 {
    width: 100%;
    text-align: center;
    font-size: 3.5em;
    color: #1f003b;
}
.card {
    box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
    padding: 5em 1em;
    border-radius: 0.6em;
    color: #1f003b;
    cursor: pointer;
    transition: 0.3s;
    background-color: #ffffff;
    margin-top: 4%;
}

.card .img-container {
    width: 8em;
    height: 8em;
    background-color: #1888ff;
    border-radius: 50%;
    margin: 0 auto 2em auto;
}

.card img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}
.card h3 {
    font-weight: 500;
}
.card p {
    font-weight: 300;
    text-transform: uppercase;
    margin: 0.5em 0 2em 0;
    letter-spacing: 2px;
}

.icons {
    width: 50%;
    min-width: 180px;
    margin: auto;
    display: flex;
    justify-content: center;
}
.card a {
    text-decoration: none;
    color: inherit;
    font-size: 1.4em;
}
.card:hover {
    background: linear-gradient(#6045ea, #1888ff);
    color: #ffffff;
}
.card:hover .img-container {
    transform: scale(1.15);
}

@media screen and (max-width: 550px) {
    .card {
        padding: 3.5em 1em;
        border-radius: 0.6em;
        color: #1f003b;
        cursor: pointer;
        transition: 0.3s;
        background-color: #ffffff;
    }
    .column {
        flex: 0% 0% 0% 0%;
        width: 100%;
        margin: 0%;
        padding: 0.5em 0;
    }
}